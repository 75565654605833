<template>
  <div>
    <b-overlay :show="isLoading" rounded="sm">
      <b-card>
        <div>
          <b-row class="d-flex justify-content-center">
            <b-col md="6">
              <b-form-group :label="'Pick Up Date'">
                <p
                  type="text"
                  class="form-control mr-2 d-flex align-items-center"
                >
                  {{ $helpers.formatDate(order.pickup_date) }}
                </p>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="'Delivery Date'">
                <p
                  type="text"
                  class="form-control mr-2 d-flex align-items-center"
                >
                  {{ $helpers.formatDate(order.delivery_date) }}
                </p>
              </b-form-group>
            </b-col></b-row
          ><b-row class="d-flex justify-content-center">
            <b-col md="6">
              <b-form-group :label="'Movement Arrangement'">
                <p
                  type="text"
                  class="form-control mr-2 d-flex align-items-center"
                >
                  {{ order.movement_arrangement }}
                </p>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="'Commodity'">
                <p
                  type="text"
                  class="form-control mr-2 d-flex align-items-center"
                >
                  {{ order.commodity }}
                </p>
              </b-form-group>
            </b-col></b-row
          >
          <b-row class="d-flex justify-content-center">
            <b-col md="6">
              <b-form-group :label="'Origin Port'">
                <p
                  type="text"
                  class="form-control mr-2 d-flex align-items-center"
                >
                  {{ order.pickup_port.port_name }}
                </p>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="'Destination Port'">
                <p
                  type="text"
                  class="form-control mr-2 d-flex align-items-center"
                >
                  {{ order.dropoff_port.port_name }}
                </p>
              </b-form-group>
            </b-col></b-row
          >
          <b-row class="d-flex justify-content-center">
            <b-col md="6">
              <b-form-group :label="'Final Dimension'">
                <p
                  type="text"
                  class="form-control mr-2 d-flex align-items-center"
                >
                  {{ order.size }}
                </p>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="'Weight'">
                <p
                  type="text"
                  class="form-control mr-2 d-flex align-items-center"
                >
                  {{ order.weight }}
                </p>
              </b-form-group>
            </b-col></b-row
          >
          <b-row class="d-flex justify-content-center">
            <b-col md="6">
              <b-form-group :label="'Quantity'">
                <p
                  type="text"
                  class="form-control mr-2 d-flex align-items-center"
                >
                  {{ order.quantity }}
                </p>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="'Invoice Value'">
                <p
                  type="text"
                  class="form-control mr-2 d-flex align-items-center"
                >
                  {{ order.invoice_number }}
                </p>
              </b-form-group>
            </b-col></b-row
          >
        </div>
        <hr />
        <div>
          <b-row class="d-flex justify-content-center">
            <b-col md="6">
              <b-form-group :label="'Sender Name (Company Name)'">
                <p
                  type="text"
                  class="form-control mr-2 d-flex align-items-center"
                >
                  {{ order.sender.name }}
                </p>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="'Recipient Name (Company Name)'">
                <p
                  type="text"
                  class="form-control mr-2 d-flex align-items-center"
                >
                  {{ order.recipient.name }}
                </p>
              </b-form-group>
            </b-col></b-row
          ><b-row class="d-flex justify-content-center">
            <b-col md="3">
              <b-form-group :label="'E-mail'">
                <p
                  type="text"
                  class="form-control mr-2 d-flex align-items-center"
                >
                  {{ order.sender.email }}
                </p>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group :label="'Contact Number'">
                <p
                  type="text"
                  class="form-control mr-2 d-flex align-items-center"
                >
                  {{ order.sender.phone }}
                </p>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group :label="'E-mail'">
                <p
                  type="text"
                  class="form-control mr-2 d-flex align-items-center"
                >
                  {{ order.recipient.email }}
                </p>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group :label="'Contact Number'">
                <p
                  type="text"
                  class="form-control mr-2 d-flex align-items-center"
                >
                  {{ order.recipient.phone }}
                </p>
              </b-form-group>
            </b-col></b-row
          >
        </div>
        <!-- <div v-if="price != 0">
          <hr />
          <b-row class="d-flex justify-content-center align-items-center">
            <b-col md="2">
              <b-form-group label="Select an option:">
                <b-form-radio name="radio-option-1" value="option-1"
                  >Option 1</b-form-radio
                >
                <b-form-radio name="radio-option-2" value="option-2"
                  >Option 2</b-form-radio
                >
                <b-form-radio name="radio-option-3" value="option-3"
                  >Option 3</b-form-radio
                >
                <b-form-radio name="radio-option-4" value="option-4"
                  >Option 4</b-form-radio
                >
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group :label="'Remarks'">
                <p class="form-control mr-2 d-flex align-items-center">
                  {{ $helpers.formatTotal(price.remarks) }}
                </p>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group :label="'Total Amount'">
                <p type="text" class="form-control">
                  {{ $helpers.formatTotal(price.price) }}
                </p>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-button variant="primary">Download Invoice</b-button>
            </b-col></b-row
          >
          <hr />
        </div> -->
        <div class="d-flex justify-content-between">
          <b-button variant="primary" @click="$router.go(-1)"
            >Back</b-button
          >
        </div></b-card
      >
      <template #overlay>
        <div class="text-center">
          <b-spinner
            style="width: 3rem; height: 3rem"
            label="Large Spinner"
            variant="primary"
          ></b-spinner>
          <h3 class="mt-3">Loading... Please wait.</h3>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  AddressDetails,
  DeliveryDetails,
  DeliveryType
} from '@/components'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    AddressDetails,
    DeliveryDetails,
    DeliveryType
  },
  props: {
    order: {
      type: [Object, Array],
      default: () => {}
    },
    price: {
      type: [Object, Array],
      default: () => {}
    }
  },
  data() {
    return {
      isLoading: false
    }
  },
  created() {},
  methods: {}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
