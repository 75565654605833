<template>
  <div>
    <h2>Sender Details</h2>
    <ValidationObserver ref="senderDetails">
      <b-row>
        <b-col md="12">
          <b-form-group :label="'Name (or Company Name)'">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Name (or Company Name)"
            >
              <input
                v-model="sender.name"
                type="text"
                class="form-control mr-2"
                placeholder="Name (or Company Name)"
                aria-label="Name (or Company Name)"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group :label="$t('email')">
            <ValidationProvider
              rules="required|email"
              v-slot="{ errors }"
              name="E-mail"
            >
              <input
                v-model="sender.email"
                type="text"
                class="form-control mr-2"
                placeholder="E-mail"
                aria-label="E-mail"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group :label="'Contact Number'">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Contact Number"
            >
              <input
                v-model="sender.phone"
                type="text"
                class="form-control mr-2"
                placeholder="Contact Number"
                aria-label="Contact Number"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-form-group :label="'Unit Number'">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Unit Number"
            >
              <input
                v-model="sender.address.unitNumber"
                type="text"
                class="form-control mr-2"
                placeholder="Unit Number"
                aria-label="Unit Number"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-form-group :label="'Address Line 1'">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Address Line 1"
            >
              <input
                v-model="sender.address.addressLine1"
                type="text"
                class="form-control mr-2"
                placeholder="Address Line 1"
                aria-label="Address Line 1"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-form-group :label="'Address Line 2'">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Address Line 2"
            >
              <input
                v-model="sender.address.addressLine2"
                type="text"
                class="form-control mr-2"
                placeholder="Address Line 2"
                aria-label="Address Line 2"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group :label="'Postal Code'">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Postal Code"
            >
              <input
                v-model="sender.address.postalCode"
                type="text"
                class="form-control mr-2"
                placeholder="Postal Code"
                aria-label="Postal Code"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group :label="'Area'">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Area"
            >
              <input
                v-model="sender.address.area"
                type="text"
                class="form-control mr-2"
                placeholder="Area"
                aria-label="Area"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group :label="'State'">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="State"
            >
              <v-select
                v-model="sender.address.state"
                :options="states"
                :clearable="false"
                label="text"
                :reduce="(state) => state.value"
                :placeholder="$t('Select a State')"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group :label="'Country'">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Country"
            >
              <v-select
                v-model="sender.address.country"
                :options="countries"
                :clearable="false"
                label="text"
                :reduce="(country) => country.value"
                :placeholder="$t('Select a Country')"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-row>
    </ValidationObserver>
    <div v-for="(recipient, index) in recipients" :key="index">
      <hr />
      <div class="d-flex justify-content-between">
        <h2>Recipient Details {{ index + 1 }}</h2>
        <b-button
          v-if="index != 0"
          variant="warning"
          @click="removeRecipient(index)"
          ><feather-icon icon="XIcon" size="14" /> Remove Recipient</b-button
        >
      </div>

      <ValidationObserver ref="recipientDetails">
        <b-row>
          <b-col md="12">
            <b-form-group :label="'Name (or Company Name)'">
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
                name="Name (or Company Name)"
              >
                <input
                  v-model="recipient.name"
                  type="text"
                  class="form-control mr-2"
                  placeholder="Name (or Company Name)"
                  aria-label="Name (or Company Name)"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group :label="$t('email')">
              <ValidationProvider
                rules="required|email"
                v-slot="{ errors }"
                name="E-mail"
              >
                <input
                  v-model="recipient.email"
                  type="text"
                  class="form-control mr-2"
                  placeholder="E-mail"
                  aria-label="E-mail"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="'Contact Number'">
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
                name="Contact Number"
              >
                <input
                  v-model="recipient.phone"
                  type="text"
                  class="form-control mr-2"
                  placeholder="Contact Number"
                  aria-label="Contact Number"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group :label="'Unit Number'">
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
                name="Unit Number"
              >
                <input
                  v-model="recipient.address.unitNumber"
                  type="text"
                  class="form-control mr-2"
                  placeholder="Unit Number"
                  aria-label="Unit Number"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group :label="'Address Line 1'">
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
                name="Address Line 1"
              >
                <input
                  v-model="recipient.address.addressLine1"
                  type="text"
                  class="form-control mr-2"
                  placeholder="Address Line 1"
                  aria-label="Address Line 1"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group :label="'Address Line 2'">
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
                name="Address Line 2"
              >
                <input
                  v-model="recipient.address.addressLine2"
                  type="text"
                  class="form-control mr-2"
                  placeholder="Address Line 2"
                  aria-label="Address Line 2"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group :label="'Postal Code'">
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
                name="Postal Code"
              >
                <input
                  v-model="recipient.address.postalCode"
                  type="text"
                  class="form-control mr-2"
                  placeholder="Postal Code"
                  aria-label="Postal Code"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="'Area'">
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
                name="Area"
              >
                <input
                  v-model="recipient.address.area"
                  type="text"
                  class="form-control mr-2"
                  placeholder="Area"
                  aria-label="Area"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group :label="'State'">
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
                name="State"
              >
                <v-select
                  v-model="recipient.address.state"
                  :options="states"
                  :clearable="false"
                  label="text"
                  :reduce="(state) => state.value"
                  :placeholder="$t('Select a State')"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="'Country'">
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
                name="Country"
              >
                <v-select
                  v-model="recipient.address.country"
                  :options="countries"
                  :clearable="false"
                  label="text"
                  :reduce="(country) => country.value"
                  :placeholder="$t('Select a Country')"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <hr />
        </b-row>
      </ValidationObserver>
    </div>
    <div class="d-flex justify-content-center">
      <b-button variant="primary" class="my-2" @click="addRecipient">
        <feather-icon icon="PlusIcon" size="14" /> Add Recipient</b-button
      >
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  name: 'Air',
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    sender: {
      type: [Object, Array],
      default: () => {}
    },
    recipient: {
      type: [Object, Array],
      default: () => {}
    }
  },
  data() {
    return {
      mainProps: {
        blank: true,
        blankColor: '#777',
        width: 75,
        height: 75,
        class: 'mr-2'
      },
      countries: [
        { text: 'Malaysia', value: 'Malaysia' },
        { text: 'Singapore', value: 'Singapore' }
      ],
      states: [
        { text: 'Kuala Lumpur', value: 'Kuala Lumpur' },
        { text: 'Perlis', value: 'Perlis' },
        { text: 'Kedah', value: 'Kedah' },
        { text: 'Pulau Pinang', value: 'Pulau Pinang' },
        { text: 'Perak', value: 'Perak' },
        { text: 'Pahang', value: 'Pahang' },
        { text: 'Kelantan', value: 'Kelantan' },
        { text: 'Terengganu', value: 'Terengganu' },
        { text: 'Selangor', value: 'Selangor' },
        { text: 'Negeri Sembilan', value: 'Negeri Sembilan' },
        { text: 'Melaka', value: 'Melaka' },
        { text: 'Johor', value: 'Johor' },
        { text: 'Sabah', value: 'Sabah' },
        { text: 'Sarawak', value: 'Sarawak' },
        { text: 'Singapore', value: 'Singapore' }
      ],
      recipients: [this.createRecipient()]
    }
  },
  methods: {
    childMethod() {
      return new Promise((resolve, reject) => {
        var result = ''
        this.$refs.senderDetails.validate().then((success) => {
          this.$refs.recipientDetails.validate().then((success) => {
            this.$emit('setSender', this.sender)
            this.$emit('setRecipient', this.recipient)
            if (success) {
              result = true
              resolve(result)
            } else {
              result = false
            }
            resolve(result)
          })
        })
      })
    },
    createRecipient() {
      return {
        name: '',
        email: '',
        phone: '',
        address: {
          unitNumber: '',
          addressLine1: '',
          addressLine2: '',
          postalCode: '',
          area: '',
          state: '',
          country: ''
        }
      }
    },

    addRecipient() {
      this.recipients.push(this.createRecipient())
    },

    removeRecipient(index) {
      this.recipients.splice(index, 1)
    }
  }
}
</script>
