var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("Sender Details")]),_c('ValidationObserver',{ref:"senderDetails"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":'Name (or Company Name)'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Name (or Company Name)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sender.name),expression:"sender.name"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"Name (or Company Name)","aria-label":"Name (or Company Name)"},domProps:{"value":(_vm.sender.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sender, "name", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('email')}},[_c('ValidationProvider',{attrs:{"rules":"required|email","name":"E-mail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sender.email),expression:"sender.email"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"E-mail","aria-label":"E-mail"},domProps:{"value":(_vm.sender.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sender, "email", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":'Contact Number'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Contact Number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sender.phone),expression:"sender.phone"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"Contact Number","aria-label":"Contact Number"},domProps:{"value":(_vm.sender.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sender, "phone", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":'Unit Number'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Unit Number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sender.address.unitNumber),expression:"sender.address.unitNumber"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"Unit Number","aria-label":"Unit Number"},domProps:{"value":(_vm.sender.address.unitNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sender.address, "unitNumber", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":'Address Line 1'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Address Line 1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sender.address.addressLine1),expression:"sender.address.addressLine1"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"Address Line 1","aria-label":"Address Line 1"},domProps:{"value":(_vm.sender.address.addressLine1)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sender.address, "addressLine1", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":'Address Line 2'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Address Line 2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sender.address.addressLine2),expression:"sender.address.addressLine2"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"Address Line 2","aria-label":"Address Line 2"},domProps:{"value":(_vm.sender.address.addressLine2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sender.address, "addressLine2", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":'Postal Code'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Postal Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sender.address.postalCode),expression:"sender.address.postalCode"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"Postal Code","aria-label":"Postal Code"},domProps:{"value":(_vm.sender.address.postalCode)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sender.address, "postalCode", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":'Area'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Area"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sender.address.area),expression:"sender.address.area"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"Area","aria-label":"Area"},domProps:{"value":(_vm.sender.address.area)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sender.address, "area", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":'State'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"State"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.states,"clearable":false,"label":"text","reduce":function (state) { return state.value; },"placeholder":_vm.$t('Select a State')},model:{value:(_vm.sender.address.state),callback:function ($$v) {_vm.$set(_vm.sender.address, "state", $$v)},expression:"sender.address.state"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":'Country'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.countries,"clearable":false,"label":"text","reduce":function (country) { return country.value; },"placeholder":_vm.$t('Select a Country')},model:{value:(_vm.sender.address.country),callback:function ($$v) {_vm.$set(_vm.sender.address, "country", $$v)},expression:"sender.address.country"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_vm._l((_vm.recipients),function(recipient,index){return _c('div',{key:index},[_c('hr'),_c('div',{staticClass:"d-flex justify-content-between"},[_c('h2',[_vm._v("Recipient Details "+_vm._s(index + 1))]),(index != 0)?_c('b-button',{attrs:{"variant":"warning"},on:{"click":function($event){return _vm.removeRecipient(index)}}},[_c('feather-icon',{attrs:{"icon":"XIcon","size":"14"}}),_vm._v(" Remove Recipient")],1):_vm._e()],1),_c('ValidationObserver',{ref:"recipientDetails",refInFor:true},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":'Name (or Company Name)'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Name (or Company Name)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(recipient.name),expression:"recipient.name"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"Name (or Company Name)","aria-label":"Name (or Company Name)"},domProps:{"value":(recipient.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(recipient, "name", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('email')}},[_c('ValidationProvider',{attrs:{"rules":"required|email","name":"E-mail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(recipient.email),expression:"recipient.email"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"E-mail","aria-label":"E-mail"},domProps:{"value":(recipient.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(recipient, "email", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":'Contact Number'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Contact Number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(recipient.phone),expression:"recipient.phone"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"Contact Number","aria-label":"Contact Number"},domProps:{"value":(recipient.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(recipient, "phone", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":'Unit Number'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Unit Number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(recipient.address.unitNumber),expression:"recipient.address.unitNumber"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"Unit Number","aria-label":"Unit Number"},domProps:{"value":(recipient.address.unitNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(recipient.address, "unitNumber", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":'Address Line 1'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Address Line 1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(recipient.address.addressLine1),expression:"recipient.address.addressLine1"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"Address Line 1","aria-label":"Address Line 1"},domProps:{"value":(recipient.address.addressLine1)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(recipient.address, "addressLine1", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":'Address Line 2'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Address Line 2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(recipient.address.addressLine2),expression:"recipient.address.addressLine2"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"Address Line 2","aria-label":"Address Line 2"},domProps:{"value":(recipient.address.addressLine2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(recipient.address, "addressLine2", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":'Postal Code'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Postal Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(recipient.address.postalCode),expression:"recipient.address.postalCode"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"Postal Code","aria-label":"Postal Code"},domProps:{"value":(recipient.address.postalCode)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(recipient.address, "postalCode", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":'Area'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Area"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(recipient.address.area),expression:"recipient.address.area"}],staticClass:"form-control mr-2",attrs:{"type":"text","placeholder":"Area","aria-label":"Area"},domProps:{"value":(recipient.address.area)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(recipient.address, "area", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":'State'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"State"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.states,"clearable":false,"label":"text","reduce":function (state) { return state.value; },"placeholder":_vm.$t('Select a State')},model:{value:(recipient.address.state),callback:function ($$v) {_vm.$set(recipient.address, "state", $$v)},expression:"recipient.address.state"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":'Country'}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.countries,"clearable":false,"label":"text","reduce":function (country) { return country.value; },"placeholder":_vm.$t('Select a Country')},model:{value:(recipient.address.country),callback:function ($$v) {_vm.$set(recipient.address, "country", $$v)},expression:"recipient.address.country"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('hr')],1)],1)],1)}),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{staticClass:"my-2",attrs:{"variant":"primary"},on:{"click":_vm.addRecipient}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"14"}}),_vm._v(" Add Recipient")],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }