<template>
  <div>
    <ValidationObserver ref="deliveryDetails">
      <b-row class="d-flex justify-content-center">
        <b-col md="4">
          <b-form-group :label="'Pick Up Address'">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Pick Up Address"
            >
              <input
                v-model="form.pickup_address"
                type="text"
                class="form-control mr-2"
                placeholder="Pick Up Address"
                aria-label="Pick Up Address"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group :label="'Pick Up Date'">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Pick Up Date"
            >
              <flat-pickr
                v-model="form.pickup_date"
                class="form-control"
                placeholder="Choose Date"
                readonly="readonly"
                :config="{
                  minDate: 'today',
                }"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group> </b-col
        ><b-col md="4">
          <b-form-group :label="'Delivery Date'">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Delivery Date"
            >
              <flat-pickr
                v-model="form.delivery_date"
                class="form-control"
                placeholder="Choose Date"
                readonly="readonly"
                :config="{
                  minDate: form.pickup_date,
                }"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- <b-row class="d-flex justify-content-left">
        <b-col md="4">
          <b-form-group :label="'Sender Address 1'">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Sender Address 1"
            >
              <input
                v-model="form.sender_address_1"
                type="text"
                class="form-control mr-2"
                placeholder="Sender Address 1"
                aria-label="Sender Address 1"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
          <div class="form-check mb-1">
            <input
              class="form-check-input"
              type="checkbox"
              id="additionalStopCheckbox"
              v-model="isAdditionalStop"
            />
            <label class="form-check-label" for="additionalStopCheckbox">
              Additional Stop Address
            </label>
          </div>
        </b-col>
        <b-col v-if="isAdditionalStop" md="4">
          <b-form-group :label="'Sender Address 2'">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Sender Address 2"
            >
              <input
                v-model="form.sender_address_2"
                type="text"
                class="form-control mr-2"
                placeholder="Sender Address 2"
                aria-label="Sender Address 2"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col v-if="isAdditionalStop" md="4">
          <b-form-group :label="'Sender Address 3'">
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
              name="Sender Address 3"
            >
              <input
                v-model="form.sender_address_3"
                type="text"
                class="form-control mr-2"
                placeholder="Sender Address 3"
                aria-label="Sender Address 3"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-row> -->
      <b-row class="mb-3">
        <b-col md="4">
          <b-row>
            <b-col md="12">
              <b-form-group :label="$t('invoice_number')">
                <ValidationProvider
                  v-slot="{ errors }"
                  :vid="`${'invoice_number'}`"
                  name="Invoice Number"
                >
                  <b-form-input
                    v-model="form.invoice_number"
                    type="text"
                    :placeholder="$t('Enter invoice number')"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group :label="$t('PO Number')">
                <ValidationProvider
                  v-slot="{ errors }"
                  :vid="`${po_number}`"
                  name="PO Number"
                >
                  <b-form-input
                    id="po-number"
                    v-model="form.po_number"
                    type="text"
                    :placeholder="$t('Enter PO number')"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group :label="$t('PO Expiry Date')">
                <ValidationProvider
                  v-slot="{ errors }"
                  :vid="`${'po_expiry_date'}`"
                  name="PO Expiry Date"
                >
                  <flat-pickr
                    id="po-expiry-date"
                    v-model="form.po_expiry_date"
                    :placeholder="$t('YYYY-MM-DD')"
                    class="form-control"
                    :config="{ minDate: defaultPoExpiryDateTime }"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="4" class="px-lg-5">
          <b-row>
            <b-col md="12">
              <b-form-group :label="$t('Select Product Category')">
                <ValidationProvider
                  v-slot="{ errors }"
                  :vid="`${'category_id'}`"
                  name="Category"
                >
                  <b-form-radio
                    :id="'frozenFood'"
                    v-model="form.category_id"
                    value="frozenFood"
                    name="category"
                  >
                    Frozen Food
                  </b-form-radio>

                  <b-form-radio
                    :id="'frozenMeat'"
                    v-model="form.category_id"
                    value="frozenMeat"
                    name="category"
                  >
                    Frozen Meat
                  </b-form-radio>

                  <b-form-radio
                    :id="'processedFrozenFood'"
                    v-model="form.category_id"
                    value="processedFrozenFood"
                    name="category"
                  >
                    Processed Frozen Food
                  </b-form-radio>

                  <b-form-radio
                    :id="'fruits'"
                    v-model="form.category_id"
                    value="fruits"
                    name="category"
                  >
                    Fruits
                  </b-form-radio>

                  <b-form-radio
                    :id="'vegetables'"
                    v-model="form.category_id"
                    value="vegetables"
                    name="category"
                  >
                    Vegetables
                  </b-form-radio>

                  <b-form-radio
                    :id="'drygoods'"
                    v-model="form.category_id"
                    value="drygoods"
                    name="category"
                  >
                    Dry Goods
                  </b-form-radio>

                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group :label="$t('Select Packaging')">
                <ValidationProvider
                  v-slot="{ errors }"
                  :vid="`${'packaging_id'}`"
                  name="Packaging"
                >
                  <b-form-radio
                    :id="'carton'"
                    v-model="form.packaging_id"
                    value="carton"
                    name="packaging"
                  >
                    Carton
                  </b-form-radio>

                  <b-form-radio
                    :id="'foamBox'"
                    v-model="form.packaging_id"
                    value="foamBox"
                    name="packaging"
                  >
                    Foam Box
                  </b-form-radio>

                  <b-form-radio
                    :id="'plastic'"
                    v-model="form.packaging_id"
                    value="plastic"
                    name="packaging"
                  >
                    Plastic
                  </b-form-radio>

                  <b-form-radio
                    :id="'crate'"
                    v-model="form.packaging_id"
                    value="crate"
                    name="packaging"
                  >
                    Crate
                  </b-form-radio>

                  <b-form-radio
                    :id="'normalBox'"
                    v-model="form.packaging_id"
                    value="normalBox"
                    name="packaging"
                  >
                    Normal Box
                  </b-form-radio>

                  <b-form-radio
                    :id="'jumbolBox'"
                    v-model="form.packaging_id"
                    value="jumbolBox"
                    name="packaging"
                  >
                    Jumbo Box
                  </b-form-radio>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <b-form-radio
                  :id="'packagingOthers'"
                  v-model="form.packaging_id"
                  value="others"
                  name="packaging"
                >
                  Others
                </b-form-radio>
                <div v-if="form.packaging_id === 'others'">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :vid="`${'packaging'}`"
                    name="packaging"
                  >
                    <b-form-input
                      :id="`otherpackaging-${index}`"
                      v-model="form.custom_package"
                      :placeholder="$t('Others')"
                      class="mt-1"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group :label="$t('Select Remarks')">
                <ValidationProvider
                  v-slot="{ errors }"
                  :vid="`${'remarks_id'}`"
                  name="Remarks"
                >
                  <b-form-radio
                    :id="'fragile'"
                    v-model="form.remarks_id"
                    value="fragile"
                    name="remarks"
                  >
                    Fragile
                  </b-form-radio>

                  <b-form-radio
                    :id="'containsAlcohol'"
                    v-model="form.remarks_id"
                    value="containsAlcohol"
                    name="remarks"
                  >
                    Contains Alcohol (Special Handling)
                  </b-form-radio>

                  <b-form-radio
                    :id="'nonHalalItems'"
                    v-model="form.remarks_id"
                    value="nonHalalItems"
                    name="remarks"
                  >
                    Non Halal Items
                  </b-form-radio>

                  <b-form-radio
                    :id="'others'"
                    v-model="form.remarks_id"
                    value="others"
                    name="remarks"
                  >
                    Others
                  </b-form-radio>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <div v-if="form.remarks_id === 'others'">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :vid="`${'otherRemarks'}`"
                    name="otherRemarks"
                  >
                    <b-form-input
                      :id="`otherremarks-${index}`"
                      v-model="form.custom_remarks"
                      :placeholder="$t('Others')"
                      class="mt-1"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="4">
          <b-form-group label="SKU">
            <ValidationProvider
              v-slot="{ errors }"
              :vid="`${'sku'}`"
              name="SKU"
            >
              <b-form-input
                :id="`sku-${index}`"
                v-model="form.sku"
                type="text"
                :placeholder="$t('Enter SKU')"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
          <b-form-group :label="$t('temperature')">
            <ValidationProvider
              v-slot="{ errors }"
              :vid="`${'temperature'}`"
              name="Temperature"
            >
              <v-select
                v-model="form.temperature"
                :placeholder="$t('Select Temperature')"
                :options="['Ambience', 'Chill', 'Frozen']"
                :clearable="false"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
          <b-form-group>
            <ValidationProvider
              v-slot="{ errors }"
              :vid="`${'size'}`"
              name="Size"
            >
              <slot name="label">
                {{ $t("Size") }}
                <feather-icon
                  :id="`size-tooltip-${index}`"
                  style="margin-bottom: 5px"
                  size="20"
                  icon="InfoIcon"
                  :variant="'primary'"
                />
                <b-tooltip
                  :target="`size-tooltip-${index}`"
                  triggers="hover"
                  no-fade
                >
                  {{ $t("Double Charge Limit") }}: <br />50 x 38 x 35 cm<br />1.6
                  x 1.2 x 1.1 ft<br />19.6 x 14.9 x 13.7 inch <br /><br />
                  {{ $t("Max Limit") }}: <br />100 x 76 x 70 cm<br />3.2 x 2.4 x
                  2.2 ft<br />39.2 x 29.8 x 27.4 inch
                </b-tooltip>
              </slot>
              <b-input-group>
                <b-form-input
                  :id="`length-${index}`"
                  v-model="form.dimension.length"
                  type="number"
                  class="border-right-0 text-center px-0"
                  :placeholder="`0 ${form.unit}`"
                  @input="setSizeValue"
                />
                <b-input-group-text
                  class="border-right-0 border-left-0 rounded-0 p-0"
                >
                  <feather-icon icon="XIcon" />
                </b-input-group-text>
                <b-form-input
                  :id="`width-${index}`"
                  v-model="form.dimension.width"
                  type="number"
                  class="border-right-0 border-left-0 rounded-0 text-center px-0"
                  :placeholder="`0 ${form.unit}`"
                  @input="setSizeValue"
                />
                <b-input-group-text
                  class="border-right-0 border-left-0 rounded-0 p-0"
                >
                  <feather-icon icon="XIcon" />
                </b-input-group-text>
                <b-form-input
                  :id="`height-${index}`"
                  v-model="form.dimension.height"
                  type="number"
                  class="border-left-0 text-center px-0"
                  :placeholder="`0 ${form.unit}`"
                  @input="setSizeValue"
                />
                <select
                  id="size-unit"
                  v-model="form.dimension_unit"
                  class="custom-select append-left"
                >
                  <option value="unit" disabled selected hidden>
                    {{ $t("src.views.pages.inside.createdelivery.unit") }}
                  </option>
                  <option value="cm">cm</option>
                  <option value="in">in</option>
                  <option value="ft">ft</option>
                </select>
              </b-input-group>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
          <b-form-group>
            <ValidationProvider
              v-slot="{ errors }"
              :vid="`${'lorry'}`"
              name="Lorry Type"
            >
              <slot name="label">
                Lorry Type
                <feather-icon
                  :id="`weight-tooltip-${index}`"
                  size="20"
                  icon="InfoIcon"
                  style="margin-bottom: 5px"
                />
                <b-tooltip
                  :target="`weight-tooltip-${index}`"
                  triggers="hover"
                  no-fade
                >
                  <div>
                    <p>
                      <strong>1 Tonne:</strong> Should allow "Weight" from 1 kg
                      to 1000kg only and QTY Pallet not more than 1
                    </p>
                    <p>
                      <strong>3 Tonne:</strong> Should allow "Weight" from 1001
                      kg to 3000kg only and QTY Pallet from 2 to 3 only
                    </p>
                    <p>
                      <strong>5 Tonne:</strong> Should allow "Weight" from 3001
                      kg to 5000kg only and QTY Pallet from 4 to 7 only
                    </p>
                    <p>
                      <strong>10 Tonne:</strong> Should allow "Weight" from 5001
                      kg to 10000kg only and QTY Pallet from 10 to 14 only
                    </p>
                    <br />
                    <p>
                      <strong
                        >Excess weight or quantity will result in double
                        charge!</strong
                      >
                    </p>
                  </div>
                </b-tooltip>
              </slot>
              <v-select
                v-model="form.lorry"
                :placeholder="'lorry'"
                :options="['1 tonne', '3 tonne', '5 tonne', '10 tonne']"
                :clearable="false"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
          <b-row class="d-flex align-items-center">
            <b-col md="6">
              <b-form-group :label="$t('quantity')">
                <ValidationProvider
                  v-slot="{ errors }"
                  :vid="`${'qty'}`"
                  name="Quantity"
                >
                  <b-form-input
                    id="product-quantity"
                    v-model="form.qty"
                    type="number"
                    :placeholder="$t('Enter quantity')"
                    @input="() => {}"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group></b-col
            >
            <b-col md="6">
              <b-form-group :label="'Weight'">
                <ValidationProvider
                  v-slot="{ errors }"
                  :vid="`${'weight'}`"
                  name="Weight"
                >
                  <b-input-group append="kg">
                    <b-form-input
                      :id="`weight-${index}`"
                      v-model="form.weight"
                      type="number"
                      :placeholder="'Enter weight'"
                      :min="getWeightRange().min"
                      :max="getWeightRange().max"
                      @input="handleWeightInput"
                    />
                  </b-input-group>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

export default {
  name: "DeliveryDetails",
  components: {
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    form: {
      type: [Object, Array],
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
    categories: {
      type: Array,
      default: () => [],
    },
    packaging: {
      type: Array,
      default: () => [],
    },
    remarks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mainProps: {
        blank: true,
        blankColor: "#777",
        width: 75,
        height: 75,
        class: "mr-2",
      },
      resetSize: {
        size: null,
        length: null,
        width: null,
        height: null,
      },
      isAdditionalStop: false,
      lorryTooltipContent: "",
    };
  },
  methods: {
    childMethod() {
      return new Promise((resolve, reject) => {
        var result = "";
        this.$refs.deliveryDetails.validate().then((success) => {
          if (success) {
            this.$emit("setForm", this.form);
            result = true;
            resolve(result);
          } else {
            result = false;
          }
          resolve(result);
        });
      });
    },
    getWeightRange() {
      const lorryType = this.form.lorry;
      switch (lorryType) {
        case "1 tonne":
          return { min: 1, max: 1000 };
        case "3 tonne":
          return { min: 1001, max: 3000 };
        case "5 tonne":
          return { min: 3001, max: 5000 };
        case "10 tonne":
          return { min: 5001, max: 10000 };
        default:
          return { min: 0, max: 0 };
      }
    },
    handleWeightInput() {
      if (this.typeOfOrder !== "single order") {
        this.$emit("calculatePrice");
        if (this.$validate.isWeightOnDoubleCharge(this.order.weight)) {
          this.$swal({
            title: "Double Charged",
            text: "You will be double charged due to size/weight limit exceeded! Are you sure want to continue?",
            icon: "warning",
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonText: "Yes",
            customClass: {
              confirmButton: "btn btn-success",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.isConfirmed) {
              this.$emit("calculatePrice");
            }
            if (result.isDismissed) {
              this.$store.dispatch("bulkDelivery/updateOrderForm", {
                data: { weight: null },
                index: this.index,
              });
            }
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
